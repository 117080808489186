import React, { useEffect, useState } from 'react'
import i18n from 'scripts/i18n'

function LanguageMenu() {
  const [currentLanguage, setCurrentLanguage] = useState<string>()

  useEffect(() => {
    setCurrentLanguage(i18n.language)
  }, [])

  const handleClick = (language: 'ja' | 'zh' | 'en') => {
    i18n.changeLanguage(language)
    setCurrentLanguage(language)
  }

  const common: React.CSSProperties = {
    fontSize: '13px',
    lineHeight: '19px',
  }

  const normal: React.CSSProperties = {
    color: '#828282',
  }

  const selected: React.CSSProperties = {
    color: '#505CB3',
    fontWeight: 'bold',
  }

  const isSelected = (language: 'ja' | 'zh' | 'en'): boolean => {
    return currentLanguage === language
  }

  const buttonStyle = (language: 'ja' | 'zh' | 'en') => {
    if (isSelected(language)) {
      return { ...common, ...selected }
    }

    return { ...common, ...normal }
  }

  return (
    <div className="flex justify-between mt-8 mb-8" style={{ width: 186 }}>
      <div
        onClick={() => handleClick('ja')}
        style={{ cursor: 'pointer' }}
        className="px-2"
      >
        <span style={buttonStyle('ja')}>日本語</span>
      </div>
      <div>
        <span style={{ color: '#5E5E5E' }}>|</span>
      </div>
      <div
        onClick={() => handleClick('en')}
        style={{ cursor: 'pointer' }}
        className="px-2"
      >
        <span style={buttonStyle('en')}>English</span>
      </div>
      <div>
        <span style={{ color: '#5E5E5E' }}>|</span>
      </div>
      <div
        onClick={() => handleClick('zh')}
        style={{ cursor: 'pointer' }}
        className="px-2"
      >
        <span style={buttonStyle('zh')}>中文</span>
      </div>
    </div>
  )
}

export default LanguageMenu
