import { fullUrl } from 'scripts/fullUrl'
import { PostUserVerifyResendSMSResponse } from 'types/ApiResponse'
import { PostUserVerifyResendSMSRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const postUserResendVerifySMS = async (
  params: PostUserVerifyResendSMSRequest,
) => {
  const res = await axiosClient.post<PostUserVerifyResendSMSResponse>(
    `${fullUrl('api')}/users/verify/resend_sms`,
    params,
  )
  return res
}
