import { AppWithSortedIndexType, ExtensionAppType } from 'types/App'

export const convertAppsToExtensionApps = (
  apps: AppWithSortedIndexType[],
): ExtensionAppType[] =>
  apps
    .filter((app) => app.connection.conn_type === 'form_based_auth')
    .map((app) => ({
      id: app.id,
      app_name: app.app_name,
      logo_image: app.logo_image,
      app_definition: app.app_definition,
      connection: {
        id: app.connection.id,
        custom_data: {
          url_match_formbased_auth_regex:
            app.connection.custom_data.url_match_formbased_auth_regex,
          url_match_for_password_update_regex:
            app.connection.custom_data.url_match_for_password_update_regex,
        },
      },
      who_can_write_identities: app.who_can_write_identities,
    }))
