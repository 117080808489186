import { AppType, ExtensionAppType } from 'types/App'
import { SendMessageToExtension } from '../common'

export class SendLockedSessionTokenToExtension extends SendMessageToExtension {
  readonly message: { lockedSessionToken?: string }
  constructor(
    message: { lockedSessionToken?: string },
    option?: { timeout?: number },
  ) {
    super(option)

    this.message = message
  }
}
