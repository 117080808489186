import { fullUrl } from 'scripts/fullUrl'
import { PostAuthorizeConfirmLoginApprovalResponse } from 'types/ApiResponse'
import { PostAuthorizeConfirmLoginApprovalRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const postAuthorizeConfirmLoginApproval = async (
  params: PostAuthorizeConfirmLoginApprovalRequest,
) => {
  let url = `${fullUrl()}/authorize/confirm_login_approval`
  if (params.is_saml) {
    url = `${url}?saml=true`
  }
  return axiosClient.post<PostAuthorizeConfirmLoginApprovalResponse>(
    url,
    params,
  )
}
