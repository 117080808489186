import { env } from './env'

export const getSubdomain = (host: string) => {
  const domain = '.' + env.SELF_DOMAIN
  const index = host.indexOf(domain)

  if (index < 0) {
    return ''
  }

  const preHost = host.slice(0, index)
  const arr = preHost.split('.')

  // subdomain.lvh.me
  if (arr.length === 1 && arr[0].length > 0) {
    return arr[0]
  }
  // .lvh.me
  return ''
}
