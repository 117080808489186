import { isExtensionInstalled } from '../../isExtensionInstalled'

export abstract class SendMessageToExtension {
  abstract readonly message: { [key: string]: any }
  private option: { timeout?: number }

  constructor(option?: { timeout?: number }) {
    this.option = {
      timeout: option?.timeout ?? 5000,
    }
  }

  exec(): Promise<
    | {
        success: false
      }
    | {
        success: true
      }
  > {
    const promise = (): Promise<
      | {
          success: false
        }
      | {
          success: true
        }
    > => {
      return new Promise((resolve) => {
        if (!isExtensionInstalled()) {
          // TODO: 拡張機能をインストールすべきユーザーか判別できるようになったら、一律にresolve扱いにしないようにする。rejectにしようかと思ったが、拡張機能をインストールすべきでないユーザーの場合、rejectとは言えないと思う。その辺を検出できないので、現状はresolveにしている。
          // ここでresolveした場合でも処理を継続し、メッセージを送信してみるためにreturnしないでいる。
          resolve({ success: false })
        }

        const callback = (event: MessageEvent) => {
          if (event.data.from === 'content-script') {
            resolve({ success: true })
            window?.removeEventListener('message', callback)
          }
        }

        window?.addEventListener('message', callback)

        window?.setTimeout(() => {
          resolve({ success: false })
          window.removeEventListener('message', callback)
        }, this.option.timeout)

        window?.postMessage(
          { message: this.message, from: 'auth-client' },
          location?.origin,
        )
      })
    }
    const result = promise()
      .then((value) => {
        if (value.success === false) {
          return {
            success: false as const,
          }
        } else {
          return {
            success: true as const,
          }
        }
      })
      .catch((error) => {
        console.error(error)
        return {
          success: false as const,
        }
      })

    return result
  }
}
