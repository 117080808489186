import { postAuthorizeConfirmLoginApproval } from 'api/postAuthorizeConfirmLoginApproval'
import { navigate } from 'gatsby'
import { PostAuthorizeConfirmLoginApprovalRequest } from 'types/ApiRequest'
import { cookies } from './cookies'
import { SendLockedSessionTokenToExtension } from './sendMessageToExtension/sendLockedSessionTokenToExtension'
import { sentryCaptureMessage } from './sentryCaptureMessage'
import { getApps } from 'api/getApps'
import { convertAppsToExtensionApps } from './convertAppsToExtensionApps'
import { SendUrlMatchFormBasedAuthParams } from './sendMessageToExtension/sendUrlMatchFormBasedAuthParams'

export const authorizeConfirmApproval = async ({
  handle,
  params,
  appClientKey,
}: {
  handle?: number
  params: PostAuthorizeConfirmLoginApprovalRequest
  appClientKey?: string | null
}): Promise<{
  result: 'success' | 'error'
}> => {
  const {
    data: { session_token, redirect_url, session_token_expired_at_minutes },
    status,
  } = await postAuthorizeConfirmLoginApproval(params)
  if (status !== 200) {
    return {
      result: 'error',
    }
  }
  if (
    typeof session_token !== 'string' ||
    session_token === '' ||
    typeof redirect_url !== 'string' ||
    redirect_url === ''
  ) {
    return {
      result: 'error',
    }
  }

  if (handle) clearInterval(handle)
  cookies.removeLockedEventId()
  if (typeof session_token_expired_at_minutes === 'undefined') {
    return {
      result: 'error',
    }
  }
  const expires = cookies.generateSessionTokenExpires(
    session_token_expired_at_minutes,
  )
  cookies.setSessionTokenToCookie(session_token ?? '', expires)
  const result = await new SendLockedSessionTokenToExtension({
    lockedSessionToken: session_token ?? '',
  }).exec()
  if (result.success) {
    // 何もしない
  } else {
    // 何もしない
  }
  getApps({
    sessionToken: session_token ?? '',
  }).then(async (response) => {
    if (response.status !== 200) {
      return
    }
    const extensionApps = convertAppsToExtensionApps(response.data.apps)
    await new SendUrlMatchFormBasedAuthParams({
      apps: extensionApps,
    }).exec()
  })
  if (redirect_url === 'apps') {
    const path = appClientKey ? `/apps?app_client_key=${appClientKey}` : '/apps'
    navigate(path, {
      replace: true,
    })
  } else {
    window.location.replace(redirect_url)
  }

  return {
    result: 'success',
  }
}
