import { ExtensionAppType } from 'types/App'
import { SendMessageToExtension } from '../common'

export class SendUrlMatchFormBasedAuthParams extends SendMessageToExtension {
  readonly message: {
    apps: ExtensionAppType[]
  }
  constructor(
    message: {
      apps: ExtensionAppType[]
    },
    option?: { timeout?: number },
  ) {
    super(option)

    this.message = message
  }
}
