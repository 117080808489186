import { fullUrl } from 'scripts/fullUrl'
import { PostUserResendVerifyEmailResponse } from 'types/ApiResponse'
import { PostUserResendVerifyEmailRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const postUserResendVerifyEmail = async (
  params: PostUserResendVerifyEmailRequest,
) => {
  const res = await axiosClient.post<PostUserResendVerifyEmailResponse>(
    `${fullUrl('api')}/users/verify/resend_email`,
    params,
  )
  return res
}
