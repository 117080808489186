import { GetAppsResponse } from 'types/ApiResponse'
import { fullUrl } from 'scripts/fullUrl'
import { axiosClient } from 'scripts/api'

export const getApps = async (params: { sessionToken: string }) => {
  const res = await axiosClient.post<GetAppsResponse>(
    `${fullUrl('api')}/enduser_device_sessions/restore_apps`,
    {
      session_token: params.sessionToken,
    },
  )
  return res
}
