export const isExtensionInstalled = () => {
  if (typeof window === 'undefined') return false

  const isInstalled = window?.document?.documentElement?.getAttribute(
    '__locked__extension__installed__',
  )
  if (isInstalled === 'true') {
    return true
  } else {
    return false
  }
}
