import { cookies } from './cookies'
import { v4 as uuidv4, validate } from 'uuid'

export const getDeviceUuid = () => {
  let uuid = cookies.get('_locked_device_uuid')

  if (!uuid || !validate(uuid)) {
    uuid = uuidv4()
  }
  return uuid
}
