import React, { useEffect, useState } from 'react'
import { cookies } from 'scripts/cookies'
import LockedLogo from 'images/lockedlogo.png'
import LanguageMenu from 'presentation/components/AuthenticationLayout/LanguageMenu'
import { useTranslation } from 'react-i18next'
import { getDeviceUuid } from 'scripts/getDeviceUuid'

type Props = {
  children: React.ReactNode
  dataTest?: string
}

export const AuthenticationLayout: React.FC<Props> = ({
  children,
  dataTest,
}) => {
  const [dataTestValue, setDataTestValue] = useState('')
  const [pathName, setPathName] = useState<string>()
  const { t } = useTranslation()

  useEffect(() => {
    if (!dataTest) return
    setDataTestValue(dataTest)
  }, [dataTest])

  useEffect(() => {
    const deviceUuid = getDeviceUuid()
    cookies.setDeviceUuidToCookie(deviceUuid)
    setPathName(location.pathname)
  }, [])

  return (
    <div
      data-test={dataTestValue}
      className="flex items-center flex-col pt-8"
      style={{ background: '#e9e9e9', minHeight: '100%' }}
    >
      <div
        className="flex items-center flex-col rounded border border-gray-700 bg-white"
        style={{ width: 320 }}
      >
        <>
          <img
            src={LockedLogo}
            style={{
              width: 96,
              display: 'block',
              margin: 'auto',
              padding: '3em 0em 2em 0em',
            }}
          />
          {children}
        </>
      </div>
      {pathName === '/login/' ||
      pathName === '/login' ||
      pathName?.includes('/saml/auth/') ? (
        <a
          href="/deviceApprovalRequest"
          style={{ color: '#8c8c8c', fontSize: '14px' }}
          className="mt-3"
        >
          ▶︎ {t('端末の承認をリクエストする')}
        </a>
      ) : (
        <></>
      )}
      <LanguageMenu />
    </div>
  )
}
